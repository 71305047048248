<script>
import Layout from "../_layouts/main"
import PageHeader from "@/components/page-header"

export default {
  components: {
    Layout,
    PageHeader,
  },
  props: {

  },
  data() {
    return {
      title: this.$t('회사 설정'),
      search: {

      },
      sort: {
        field: 'createdAt',
        directive: -1
      },
      list: [],
      limit: 1000,
      totalCount: 0, // 총갯수
      totalPageCount: 0, // 총 페이지수
      page: 1, // 현재 페이지
    }
  },
  created() {

  },
  unmounted() {

  },
  mounted() {
    this.searchList().then()
  },
  methods: {
    setSort: function(field) {
      let directive
      if (field === this.sort.field) {
        directive = this.sort.directive === -1 ? 1 : -1
      } else {
        directive = -1
      }

      this.sort.field = field
      this.sort.directive = directive

      this.searchList().then()
    },
    searchByPage: function(page) {
      this.page = page
      this.searchList().then()
    },
    searchList: async function() {
      const loader = this.$loading.show()
      try {
        const params = {
          search: {},
          sort: {},
          limit: this.limit,
          page: this.page
        }
        params.sort[this.sort.field] = this.sort.directive

        this.list = []
        const result = await this.$API.setting.companies(params)
        if (result.code !== 0) {
          return alert(result.msg)
        }

        this.list = []
        for(const key in result.documents) {
          const company = result.documents[key]
          company.active = company.active === 1
          company.maintenance = company.maintenance === 1
          company.server.data = null
          this.list.push(company)
        }

        this.totalCount = result.totalCount
        this.totalPageCount = result.totalPageCount
        this.page = result.page

      } catch(e) {
        this.$log.error(e)
      } finally {
        loader.hide()
      }
    },
    saveActive: async function(index) {
      const company = this.list[index]
      if (company) {
        const result = await this.$API.setting.saveCompanyActive(company._id, company.active === true ? 1 : 0)
        if (result.code !== 0) {
          return alert(result.msg)
        }
      }
    },
    saveMaintenance: async function(index) {
      const company = this.list[index]
      if (company) {
        const result = await this.$API.setting.saveCompanyMaintenance(company._id, company.maintenance === true ? 1 : 0)
        if (result.code !== 0) {
          return alert(result.msg)
        }
      }
    },
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive mb-0 rounded">
              <table class="table align-middle table-nowrap">
                <thead class="table-dark">
                <tr>
                  <th class="align-middle">회사</th>
                  <th class="align-middle">게임사</th>
                  <th class="align-middle">활성화</th>
                  <th class="align-middle">점검</th>
                </tr>
                </thead>
                <tbody>
                <tr class="bg-soft" v-for="(company, index) in list" :key="company._id" :class="{'bg-secondary': !company.active, 'bg-danger': company.maintenance}">
                  <td>
                    <div>{{ company.name }}</div>
                    <div class="small">{{ new Date(company.createdAt).formatUTC('YYYY-MM-DD HH:mm:ss') }}</div>
                  </td>
                  <td class="text-start">
                    <ul class="list-group">
                      <li class="list-group-item" v-for="vendor in company.vendors" :key="'vendor_' + vendor._id">
                        {{ vendor.names[$store.getters['auth/user'].language] }}
                      </li>
                    </ul>
                  </td>
                  <td>
                    <div class="form-group">
                      <div class="form-check form-switch form-switch-lg d-inline-block" dir="ltr">
                        <input class="form-check-input" type="checkbox" :id="'active' + company._id" v-model="company.active" @change="saveActive(index)">
                        <label class="form-check-label" :for="'active' + company._id">활성화</label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <div class="form-check form-switch form-switch-lg d-inline-block" dir="ltr">
                        <input class="form-check-input" type="checkbox" :id="'maintenance' + company._id" v-model="company.maintenance" @change="saveMaintenance(index)">
                        <label class="form-check-label" :for="'maintenance' + company._id">점검</label>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
